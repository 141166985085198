import { graphql, Link } from 'gatsby'
import React from 'react'
import Layout from '../components/Layout'
import PhotoPostListing from '../components/PhotoPostListing'
import BlogPostListing from '../components/BlogPostListing'
import MusingPostListing from '../components/MusingPostListing'
import SEO from '../components/seo'

const getListing = node => {
  switch (node.fields.collection) {
    case 'photos':
      return <PhotoPostListing node={node} key={node.id} />
    case 'writing':
    case 'blog':
      return node.frontmatter.title ? (
        <BlogPostListing node={node} key={node.id} />
      ) : (
        <MusingPostListing node={node} key={node.id} />
      )
  }
}

const TagPage = ({ data, pageContext: { tag } }) => {
  const { nodes, totalCount } = data.allMdx

  return (
    <Layout>
      <SEO title={`Tagged with ${tag}`} />
      <h2 style={{ marginTop: 0, marginRight: 36 }}>
        {totalCount} post{totalCount !== 1 && 's'} tagged with “{tag}”
      </h2>
      {nodes.map(getListing)}
      <p>
        <Link to="/tags/">Browse all tags</Link>
      </p>
    </Layout>
  )
}

export default TagPage

export const pageQuery = graphql`
  query TagPage($tag: String) {
    allMdx(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] }, draft: { ne: true } } }
    ) {
      totalCount
      nodes {
        id
        body
        excerpt
        fields {
          slug
          collection
          tagSlugs
        }
        frontmatter {
          title
          date(formatString: "MMMM Do, YYYY")
          dateTime: date(formatString: "MMMM Do, YYYY, h:mm A")
          dataDate: date
          tags
          photos {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`
