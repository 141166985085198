import { css } from '@emotion/react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import { rhythm, MOBILE_MEDIA_QUERY } from '../utils/typography'

const height = 190

const PhotoPostListing = ({ node, grid }) => {
  const aspectRatio =
    1 / node.frontmatter.photos[0].childImageSharp.gatsbyImageData.height
  return (
    <div
      className="photo-index-container"
      css={
        grid
          ? css`
              flex-grow: 1;
              width: ${height * aspectRatio}px;
              @media (min-width: 769px) {
                min-height: ${height}px;
              }
            `
          : css`
              margin-bottom: ${rhythm(1)};
              ${MOBILE_MEDIA_QUERY} {
                grid-column: 1 / -1;
              }
            `
      }
    >
      <Link to={node.fields.slug}>
        <GatsbyImage
          alt="First picture"
          image={node.frontmatter.photos[0].childImageSharp.gatsbyImageData}
        />
      </Link>
    </div>
  )
}

export default PhotoPostListing
